<template>
  <div>
    <div
      class="chatbox"
      :class="{
        'frosted-background-static': $isSafari,
        'frosted-background': !$isSafari,
      }"
    >
      <div class="has-text-centered" style="font-family: cabin_sketch_regular; font-size: 20px">
        Chatbox
      </div>
      <div ref="messageBox" class="message-box has-text-left">
        <div class="un-message" v-for="message in messages_chatbox">
          <span v-if="'$t' in message" :style="{'color':message.color}">
            <template v-if="'$t_params' in message">{{ $t(message['$t'], message['$t_params']) }}</template>
            <template v-else>{{ $t(message['$t']) }}</template>
          </span>
          <span v-else>
            <i style="color: #136a9e">{{ message.j }}</i> : {{ message.msg }}
          </span>
        </div>
      </div>

      <div class="field has-addons input-message">
        <p class="control is-expanded">
          <input
            class="input"
            :class="{'is-warning': le_joueur_va_taper_son_mot_dans_la_chatbox_omg}"
            @keyup.enter="sendMessage"
            v-model="new_message"
            :maxlength="$sketchy_chatbox_max"
            @focus="chatboxFocus"
            @blur="chatboxUnfocus"
            @click="chatboxClick"
            :readonly="chatbox_e3_locked"
            :style="{'cursor': (chatbox_e3_locked && !chatbox_e3_warning) ? 'default' : undefined}"
          />
        </p>
        <p class="control">
          <button
            class="button"
            :class="{'is-warning': le_joueur_va_taper_son_mot_dans_la_chatbox_omg}"
            @click="sendMessage"
            :disabled="chatbox_e3_locked || new_message.trim().length === 0"
          >Chat</button>
        </p>
      </div>
    </div>

    <div
      v-if="chatbox_e3_warning"
      class="has-text-centered shake"
      style="width: 300px; margin-top: 10px"
    >
      <span class="tag is-danger">{{ $t('sketchy.page_jeu.chatbox.dont_send_guessed_word') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Chatbox',

  data() {
    return {
      new_message: '',

      chatbox_input_has_focus: false,

      chatbox_e3_locked: false,
      chatbox_e3_warning: false,
    }
  },

  computed: {
    nb_messages_chatbox() {
      return this.messages_chatbox.length;
    },

    le_joueur_va_taper_son_mot_dans_la_chatbox_omg() {
      return this.salon_etat === 'e3' && !this.flag_e3_mot_ok && this.chatbox_input_has_focus;
    },
  },

  methods: {
    sendMessage() {
      if (this.new_message.trim().length > 0) {
        this.$emit('sendMsg', 'chatbox_msg', this.new_message);
        this.new_message = '';
      }
    },

    chatboxClick() {
      if (this.le_joueur_va_taper_son_mot_dans_la_chatbox_omg) {
        if (!this.chatbox_e3_warning) {
          this.chatbox_e3_warning = true;
        } else {
          this.chatbox_e3_locked = false;
        }
      }
    },

    chatboxFocus() {
      this.chatbox_input_has_focus = true;
    },

    chatboxUnfocus() {
      this.chatbox_input_has_focus = false;
      this.chatbox_e3_warning = false;
    },
  },

  watch: {
    'nb_messages_chatbox': {
      handler: function () {
        this.$nextTick(() => {
          this.$refs.messageBox.scrollTop = this.$refs.messageBox.scrollHeight;
        });
      },
      immediate: true,
    },

    'salon_etat': {
      handler: function(salon_etat) {
        if (salon_etat === 'e3') {
          this.chatbox_e3_locked = true;
        }
      },
      immediate: true,
    },

    'flag_e3_mot_ok': {
      handler: function(flat) {
        if (flat) {
          this.chatbox_e3_locked = false;
          this.chatbox_e3_warning = false;
        }
      },
      immediate: true,
    },
  },

  props: ['messages_chatbox', 'salon_etat', 'flag_e3_mot_ok'],
}
</script>

<style scoped lang="scss">
.chatbox {
  margin-top: 20px;
  border-radius: 12px;

  width: 300px;
  height: 300px;
  border: 1px solid rgba(0, 0, 0, 0.2);

  .input-message {
    margin: 10px;
  }

  .message-box {
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 12px;
    height: 200px;
    overflow-y: auto;

    margin: 10px;

    .un-message {
      margin-right: 5px;
      margin-left: 5px;
      overflow-wrap: break-word;
      font-size: 14px;
    }
  }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.shake {
  animation: shake 600ms cubic-bezier(.36,.07,.19,.97) both;
  animation-iteration-count: 1;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>